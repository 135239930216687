@font-face{
    font-family:'AlicorpWeb-Bold';
    src:url('bold/Gotham-Bold.eot');
    src:url('bold/Gotham-Bold.eot') format('embedded-opentype'),
    url('bold/Gotham-Bold.woff2') format('woff2'),
    url('bold/Gotham-Bold.woff') format('woff'),
    url('bold/Gotham-Bold.svg') format('svg');
}

@font-face {
    font-family:'AlicorpWeb-Bold-Italic';
    src: url('bold-italic/Gotham-BoldItalic.otf');
}

@font-face{
    font-family:'AlicorpWeb-Book';
    src:url('book/Gotham-Book.eot');
    src:url('book/Gotham-Book.eot') format('embedded-opentype'),
    url('book/Gotham-Book.woff2') format('woff2'),
    url('book/Gotham-Book.woff') format('woff'),
    url('book/Gotham-Book.svg') format('svg');
}

@font-face {
    font-family:'AlicorpWeb-Book-Italic';
    src: url('book-italic/Gotham-BookItalic.otf');
}

@font-face{
    font-family:'AlicorpWeb-Light';
    src:url('light/Gotham-Light.eot');
    src:url('light/Gotham-Light.eot') format('embedded-opentype'),
    url('light/Gotham-Light.woff2') format('woff2'),
    url('light/Gotham-Light.woff') format('woff'),
    url('light/Gotham-Light.svg') format('svg');
}

@font-face {
    font-family:'AlicorpWeb-Light-Italic';
    src: url('light-italic/Gotham-LightItalic.otf');
}

@font-face{
    font-family:'AlicorpWeb-Medium';
    src:url('medium/Gotham-Medium.otf');
    src:url('medium/Gotham-Medium.otf') format('embedded-opentype'),
    url('/medium/Gotham-Medium.eot') format("embedded-opentype")
    url('medium/Gotham-Medium.woff2') format('woff2'),
    url('medium/Gotham-Medium.woff') format('woff'),
    url('medium/Gotham-Medium.svg') format('svg');
}

@font-face {
    font-family:'AlicorpWeb-Medium-Italic';
    src: url('medium-italic/Gotham-MediumItalic.otf');
}
@font-face{
    font-family:'AlicorpWeb-SemiBold';
    src:url('semiBold/AlicorpSans-SemiBold.otf');
    src:url('semiBold/AlicorpSans-SemiBold.otf') format('embedded-opentype'),
    url('/semiBold/AlicorpSans-SemiBold.eot') format("embedded-opentype")
    url('semiBold/AlicorpSans-SemiBold.woff2') format('woff2'),
    url('semiBold/AlicorpSans-SemiBold.woff') format('woff'),
    url('semiBold/AlicorpSans-SemiBold.eot') format('eot');
}

@font-face{
    font-family:'AlicorpSans-regular';
    src:url('OTF/AlicorpSans-Regular.otf');
    src:url('OTF/AlicorpSans-Regular.otf') format('embedded-opentype'),
    url('/EOT/AlicorpSans-Regular.eot') format("embedded-opentype")
    url('WOFF2/AlicorpSans-Regular.woff2') format('woff2'),
    url('WOFF/AlicorpSans-Regular.woff') format('woff'),
    url('EOT/AlicorpSans-Regular.eot') format('eot');
}
@font-face{
    font-family:'AlicorpSans-bold';
    src:url('OTF/AlicorpSans-Bold.otf');
    src:url('OTF/AlicorpSans-Bold.otf') format('embedded-opentype'),
    url('/EOT/AlicorpSans-Bold.eot') format("embedded-opentype")
    url('WOFF2/AlicorpSans-Bold.woff2') format('woff2'),
    url('WOFF/AlicorpSans-Bold.woff') format('woff'),
    url('EOT/AlicorpSans-Bold.eot') format('eot');
}
@font-face{
    font-family:'AlicorpSans-semibold';
    src:url('OTF/AlicorpSans-SemiBold.otf');
    src:url('OTF/AlicorpSans-SemiBold.otf') format('embedded-opentype'),
    url('/EOT/AlicorpSans-SemiBold.eot') format("embedded-opentype")
    url('WOFF2/AlicorpSans-SemiBold.woff2') format('woff2'),
    url('WOFF/AlicorpSans-SemiBold.woff') format('woff'),
    url('EOT/AlicorpSans-SemiBold.eot') format('eot');
}
@font-face{
    font-family:'AlicorpSans-BoldItalic';
    src:url('OTF/AlicorpSansItalic-BoldItalic.otf');
    src:url('OTF/AlicorpSansItalic-BoldItalic.otf') format('embedded-opentype'),
    url('/EOT/AlicorpSansItalic-BoldItalic.eot') format("embedded-opentype")
    url('WOFF2/AlicorpSansItalic-BoldItalic.woff2') format('woff2'),
    url('WOFF/AlicorpSansItalic-BoldItalic.woff') format('woff'),
    url('EOT/AlicorpSansItalic-BoldItalic.eot') format('eot');
}