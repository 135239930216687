@import "../base/colors.scss";

.new-card {
  display: inline-block;
  padding: 3px 5px 2px 5px;
  margin-left: 5px;
  height: 20px;
  align-items: center;
  display: flex;
  border-radius: 2px;
  font-family: $font-family-medium;
  font-size: $text-size-10;
}

.new-item {
  @extend .new-card;
  background-color: $color-primary;
  color: $color-white;
}

.new-item-hover {
  @extend .new-card;
  background-color: $color-white;
  color: $color-primary;
}
.body-history {
  background-color: $color-white;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  line-height: inherit;
  border-bottom: none;
  min-height: 50px;

  &:hover {
    background-color: rgba(146, 168, 177, 0.2) !important;
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
  }
}
.alert-danger {
  color: $color-red-alert;
  background-color: $color-red-0;
  border-color: $color-red-0;
  position: relative;
  border-radius: 5px;
  border: 1px solid transparent;
}

.alert-success {
  color: #1d643b;
  background-color: #d7f3e3;
  border-color: #d7f3e3;
  position: relative;
  border-radius: 5px;
  border: 1px solid transparent;
}
