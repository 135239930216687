/* You can add global styles to this file, and also import other style files */
@import "src/assets/themes/theme";
@import "./node_modules/ngx-red-zoom/styles/mixins/style-window";

html,
body {
  height: 100%;
  top: 0 !important;
}

body {
  margin: 0;
  font-family: "AlicorpWeb-Book", sans-serif;
  color: #202020;
  background: $dl-color-neutral-light;
  font-size: 0.9rem;
  line-height: 1.6;
  font-weight: 400;
}

div {
  box-sizing: border-box;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick,
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #6c6c6c;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
  .mat-form-field-outline-thick {
  border: 1px;
}
// .mat-form-field-appearance-outline .mat-form-field-outline {
//   color: #ced4da;
//   background: #FFFFFF;
// }

// .mat-form-field-wrapper {
//   padding-bottom: 1em;
// }

// .mat-form-field-outline-gap {
//   position: absolute;
//   color: transparent;
// }

// .mat-form-field-appearance-outline .mat-form-field-suffix {
//   top: 0.1em !important;
// }

// .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
//   position: relative;
//   transform: 0;
// }

.mat-autocomplete-panel.mat-autocomplete-visible {
  box-shadow: none;
  padding-top: 4px;
  margin-top: -4px;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
}

.red-zoom--style--window {
  @include red-zoom-style-window($overlay-color: rgba(#484d4f, 0.5));

  .red-zoom__frame:after {
    border: 1px solid #646b6e;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }

  .red-zoom__lens {
    --red-zoom-min-scale-factor: 1.5;
    --red-zoom-max-scale-factor: 2;

    min-width: calc(var(--red-zoom-thumb-w) * var(--red-zoom-min-scale-factor));
    min-height: calc(
      var(--red-zoom-thumb-h) * var(--red-zoom-min-scale-factor)
    );
    max-width: calc(var(--red-zoom-thumb-w) * var(--red-zoom-max-scale-factor));
    max-height: calc(
      var(--red-zoom-thumb-h) * var(--red-zoom-max-scale-factor)
    );

    --window-x: calc(var(--red-zoom-thumb-x, -0px) + 70px);
    --red-zoom-thumb-w: 350px;
    --red-zoom-thumb-h: 400px;

    left: var(--window-x, 0px);
    top: calc(var(--red-zoom-thumb-y, 0px) + 4px);
    width: var(--red-zoom-thumb-w, 0px);
    height: var(--red-zoom-thumb-h, 0px);

    &::after {
      border: 4px solid #646b6e;
      left: -4px;
      top: -4px;
      bottom: -4px;
      right: -4px;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 850px) {
      --window-x: calc(var(--red-zoom-thumb-x, -0px) + 85px);
    }
  }
}

.mat-autocomplete-panel {
  max-height: 16.5rem !important;
}

// .cdk-overlay-pane {
//   max-height: 620px !important;
// }

// @media only screen and (max-width: 767.9px) {
//   .cdk-overlay-pane {
//     max-height: 384px !important;
//   }
// }
.mat-select-panel-wrap {
  margin-top: 2.4rem;
  margin-left: 0.5rem;
  .mat-select-panel {
    min-width: calc(100% + 48px) !important;
    max-height: 21rem;
  }
}
.mat-option-text {
  width: 100%;
}

strong {
  font-family: $font-family-medium;
}
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.quantity-zero {
  max-width: 95% !important;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 80vw;
  }

  .mat-dialog-container {
    max-width: 444px !important;

    @media (min-width: 768px) {
      min-width: 444px !important;
    }
  }
}
.suggested-order-loader {
  max-width: 21.625rem !important;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 80vw;
  }

  .mat-dialog-container {
    max-width: 21.625rem !important;
    padding: 32px !important;
    border-radius: 0.5rem !important;
    @media (max-width: 768px) {
      min-width: 20rem !important;
    }
    @media (max-width: 380px) {
      min-width: 80% !important;
      width: 80% !important;
    }
  }
}
.error-suggested-order {
  max-width: 31.5rem !important;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 20rem !important;
  }
  @media (max-width: 380px) {
    min-width: 80% !important;
    width: 80% !important;
  }
  .mat-dialog-container {
    max-width: 31.5rem !important;
    padding: 1.5rem !important;
    border-radius: 0.5rem !important;
    @media (max-width: 768px) {
      min-width: 20rem !important;
    }
    @media (max-width: 380px) {
      min-width: 80% !important;
      width: 80% !important;
    }
  }
}
.suggested-order {
  overflow: hidden !important;
  max-width: 55rem !important;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 43rem;
  max-height: 90vh;
  @media (max-width: 1000px) {
    max-width: 100% !important;
  }

  .mat-dialog-container {
    overflow: hidden !important;
    max-width: 55rem !important;
    padding: 0 !important;
    border-radius: 0.5rem !important;
    min-height: 43rem !important;
    max-height: 90vh !important;
    @media (max-width: 1000px) {
      min-width: 90% !important;
      max-width: 90% !important;
    }
  }
}
.satifaction-suggested-order {
  max-width: 30rem !important;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    max-width: 100% !important;
  }
  @media only screen and (max-width: 767.9px) {
    max-width: 20rem !important;
  }
  @media only screen and (max-width: 340.9px) {
    max-width: 80% !important;
  }
  .mat-dialog-container {
    max-width: 30rem !important;
    padding: 1.5rem !important;
    border-radius: 0.5rem !important;
    @media (max-width: 1000px) {
      max-width: 30rem !important;
    }
    @media (max-width: 767.9px) {
      max-width: 20rem !important;
    }
  }
}
.check-stock-issue {
  max-width: 95% !important;

  @media (min-width: 768px) {
    max-width: 390px !important;
    min-height: auto;
  }

  .mat-dialog-container {
    padding-top: 40px;
    padding-bottom: 32px;
    padding-left: 26px;
    padding-right: 26px;
  }
}

.check-stock {
  max-width: 95% !important;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 80vw;
  }

  .mat-dialog-container {
    max-width: 444px !important;

    @media (min-width: 768px) {
      min-width: 444px !important;
    }
  }
}
.charging {
  max-width: 384px !important;
  padding: 0;
  .mat-dialog-container {
    padding: 0;
    border-radius: 0.5rem;
  }
  @media (max-width: 768px) {
    max-width: 320px !important;
  }
  @media (max-width: 340px) {
    max-width: 90% !important;
  }
}
.excel-table {
  min-height: 539px;
  max-width: 95% !important;

  @media (min-width: 768px) {
    max-width: 80vw;
    min-height: auto;
  }

  .mat-dialog-container {
    padding-top: 40px;
    padding-bottom: 32px;
    padding-left: 26px;
    padding-right: 26px;
  }
}

.excel-upload-download {
  min-height: 450px;
  max-height: 760px !important;
  max-width: 95% !important;

  @media (min-width: 768px) {
    max-width: 80vw;
    min-height: auto;
  }

  .mat-dialog-container {
    padding-top: 40px;
    padding-bottom: 32px;
    padding-left: 26px;
    padding-right: 26px;
  }
}

.excel-table-check {
  min-height: 444px;
  max-height: 664px !important;
  max-width: 95% !important;

  @media (min-width: 768px) {
    max-width: 95% !important;
    min-height: auto;
  }

  .mat-dialog-container {
    padding-top: 40px;
    padding-bottom: 32px;
    padding-left: 26px;
    padding-right: 26px;
  }
}

.modal-add-product {
  .mat-dialog-container {
    padding: 30px 20px;
  }
}

.mat-dialog-container {
  .dk-overlay-pane {
    box-shadow: none;
    padding-top: 40px;
    padding-bottom: 32px;
    padding-left: 26px;
    padding-right: 26px;
    box-sizing: content-box !important;
    min-width: 150px !important;
    max-height: calc(427px + 40px + 81px) !important;
  }
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-table {
  box-shadow: none !important;
}

.mat-progress-bar-buffer {
  background-color: $dl-color-neutral-medium;
}

.accountRed-progress-bar {
  .mat-progress-bar-fill::after {
    background-color: $dl-color-support-positive-light;
  }
}
.compliance-green {
  .mat-progress-bar-fill::after {
    background-color: $dl-color-support-positive-light;
  }
}

.compliance-orange {
  .mat-progress-bar-fill::after {
    background-color: $dl-color-support-warning-medium;
  }
}
.compliance-negative {
  .mat-progress-bar-fill::after {
    background-color: $dl-color-support-negative-medium;
  }
}
.compliance-default {
  .mat-progress-bar-fill::after {
    background-color: $dl-color-neutral-dark;
  }
}
.compliance-blue {
  .mat-progress-bar-fill::after {
    background-color: $dl-color-support-purple-light;
  }
}
.cdk-global-scrollblock {
  // overflow: auto;
}

.modal-open {
  overflow: hidden !important;
}
.modal-open-order {
  overflow: hidden !important;
}

.carousel-arrow-prev,
.carousel-arrow-next {
  border-radius: 4px !important;
  opacity: 0.8 !important;
}

.pi-spinner:before {
  padding: 8px;
}

.whatsapp-component {
  .floating-wpp {
    bottom: 180px;
  }
}
.button-client:focus,
.nav-link:focus {
  outline: none; /* Anular estilo por defecto */
  border: 1px solid transparent; /* Evitar que aparezca el contorno */
}
.button-x-style,
.material-symbols-rounded {
  font-size: $text-size-title !important;
  padding: 0;
  display: flex;
  min-width: 32px;
  justify-content: end;
}
.container-border-line,
.container-border-line-red,
.container-border-line-black {
  border-radius: 0.5rem;
}

.container-border-line {
  border: 1px solid $dl-color-neutral-medium;
}
.container-border-line-red {
  border: 1px solid $dl-color-support-negative-light;
}

.container-border-line-black {
  border: 1px solid $dl-color-neutral-dark;
}
.custom-menu-end {
  margin-bottom: 1rem;
}
.mat-option {
  height: auto !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

@media only screen and (max-width: 980px) {
  .modal-open-order {
    overflow: auto !important;
  }
}
