@import "../base/colors.scss";

.select-general {
  width: 100%;
  color: $dl-color-neutral-darkest !important;
  min-width: 14rem;

  ::ng-deep.mat-form-field-wrapper {
    padding-bottom: 0;
    height: 3.5rem;
    overflow: hidden;

    .mat-form-field-flex {
      height: 3.5rem;
      width: 100%;

      .mat-form-field-infix {
        padding: 0 !important;
        display: flex !important;
        align-items: center !important;
        height: 3.5rem;
        flex-wrap: nowrap;
        width: auto !important;
        min-width: 4rem !important;

        .mat-select {
          width: 92%;
          font-family: $dl-font-family-regular;
          font-size: 1rem;

          .mat-select-trigger {
            .mat-select-value {
              color: $dl-color-neutral-darkest;
            }
          }
        }

        .mat-select-disabled {
          .mat-select-trigger {
            .mat-select-value {
              color: $dl-color-neutral-dark !important;
            }
          }
        }
      }

      .mat-form-field-outline {
        height: 3.5rem;
        color: $dl-color-neutral-medium !important;
        border: none !important;

        .mat-form-field-outline-end {
          border-radius: 0 0.5rem 0.5rem 0;
          border-width: 1px;
        }

        .mat-form-field-outline-gap,
        .mat-form-field-outline-thick {
          border-width: 1px !important;
        }

        .mat-form-field-outline-start {
          border-radius: 0.5rem 0 0 0.5rem !important;
          border-width: 1px;
        }

        &:focus-within {
          border-width: 1px;
          /* Ajusta el valor según sea necesario */
        }
      }

      .mat-form-field-outline:focus-visible {
        outline: none;
      }
    }
  }
}

.select-general-40 {
  width: 100%;
  color: $dl-color-neutral-darkest !important;
  min-width: 14rem;

  ::ng-deep.mat-form-field-wrapper {
    padding-bottom: 0;
    height: 2.5rem;
    overflow: hidden;

    .mat-form-field-flex {
      height: 2.5rem;
      width: 100%;

      .mat-form-field-infix {
        padding: 0 !important;
        display: flex !important;
        align-items: center !important;
        height: 2.3rem;
        flex-wrap: nowrap;
        width: auto !important;
        min-width: 4rem !important;

        .mat-select {
          width: 90%;
          font-family: $dl-font-family-regular;
          font-size: 14px;

          .mat-select-trigger {
            .mat-select-value {
              color: $dl-color-neutral-dark;
              .mat-select-placeholder{
                color: $dl-color-neutral-dark !important;
              }
            }
          }
        }

        .mat-select-disabled {
          .mat-select-trigger {
            .mat-select-value {
              color: $dl-color-neutral-dark !important;
              .mat-select-placeholder{
                color: $dl-color-neutral-dark !important;
              }
            }
          }
        }
      }

      .mat-form-field-outline {
        height: 2.5rem;
        color: $dl-color-neutral-dark;
        border: none !important;
        background: $dl-color-neutral-lightest;
        border-radius: 0.5rem;

        .mat-form-field-outline-end {
          border-radius: 0 0.5rem 0.5rem 0;
          border-width: 1px;
        }

        .mat-form-field-outline-gap,
        .mat-form-field-outline-thick {
          border-width: 1px !important;
        }

        .mat-form-field-outline-start {
          border-radius: 0.5rem 0 0 0.5rem !important;
          border-width: 1px;
        }

        &:focus-within {
          border-width: 1px;
          /* Ajusta el valor según sea necesario */
        }
      }

      .mat-form-field-outline:focus-visible {
        outline: none;
      }
    }
  }
}

::ng-deep.mat-option-text {
  display: flex !important;
  height: fit-content;
  align-items: center !important;
  justify-content: space-between !important;
  line-height: 20px;

  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon-check {
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
