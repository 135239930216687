@import '../base/colors.scss';

.product-footer {
  width: 100%;
  -webkit-box-shadow: 8px -1px 16px 1px $color-shadow-gray-rgb;
  box-shadow: 8px -1px 16px 1px $color-shadow-gray-rgb;
  background-color: $color-white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;

  .container {
    padding: 16px;
    display: flex;
    justify-content: flex-end;

    .btn-add-checkout {
      margin-left: 5px;
    }
  }
}
footer {
  background: $color-text-black;
  color: $color-white;
  position: fixed;
  bottom: 0;
}

.footer-copyright {
  display: flex;
  justify-content: space-between;
}

.text-tyc {
  a {
      text-decoration: none;
      color: $color-white;
  }
}

@media only screen and (max-width: 767.9px) {

  .payment-data,
  .card-type-payment,
  .selectAddress {
    width: 100%;
  }

  .card-type-payment {
    margin-bottom: 22px;
  }

  .content-type-payment {
    display: block;
  }

  .img-photo {
    img {
      width: 80px;
      height: 80px;
    }
  }

  .product-footer .container {
    flex-direction: column;
  }

  .product-footer .container .btn-back {
    margin-bottom: 8px;
  }

  .product-footer .container .btn-add-checkout {
    margin-left: 0;
  }
  .footer-copyright {
    display: block;
    justify-content: center;
    text-align: center;
}
}