.input-car {
    box-shadow: none;
    background-color: $color-white;
    border: 1px solid $dl-color-neutral-medium;
    outline: none;
    border-radius: 5px;
    color: $dl-color-neutral-dark;
    font-family: $dl-font-family-regular;
    font-size: 16px;
    line-height: inherit;
    width: 72px;
    padding-right: 8px;
    padding-left: 8px;
     /* Chrome, Safari, Edge, Opera */
     &::-webkit-outer-spin-button,
     &::-webkit-inner-spin-button {
       -webkit-appearance: none;
       margin: 0;
     }
 
     /* Firefox */
     &[type=number] {
       -moz-appearance: textfield;
     }
}
