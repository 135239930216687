@import '../base/colors.scss';

//MEDIDAS OFICIALES 1024 - 768 - 425
.show-xms {
    display: none;
}


// EXCEPCIONES DE RESPONSIVE PARA IGUALAR A VERSION 1
@media only screen and (max-width: 1160px) {
    .nav-container {
        padding: 0;
    }
}

@media only screen and (max-width: 1023.9px) {
    .center-button-my-account {
        justify-content: center;
    }

    // HIDDEN
    .hidden-text-nav-sm {
        display: none !important;
    }


}

@media only screen and (max-width: 767.9px) {
    .container-box {
        max-width: 80%;
    }

    //ocultar mensaje de welcome
    .hidden-text-welcome {
        display: none !important;
    }

    //CAMBIAR TAMAÑO DE TEXTOS
    .text-header-title {
        padding-top: 2rem;
        line-height: $text-size-title;
        margin-bottom: 49px;
    }

    .text-info-login {
        padding-bottom: 2rem;
    }

    // HIDDEN
    .hidden-text-cat-car {
        display: none !important;
    }
    .logo-welcome {
        width: auto;
    }

    .input-search-mobile {
        width: calc(100% - 18px);
    }

    .button-categories-responsive-mobile {
        text-align: center;
        width: 25%;
        display: flex;
        justify-content: center;
    }

    .container-button-menu {
        width: 100%;
    }

    .hidden-xms {
        display: none !important;
    }

    .show-xms {
        display: flex;
    }

    .showStyle {
        width: 75%;
    }
}

@media only screen and (max-width: 426px) {

    //CAMBIAR TAMAÑO DE TEXTOS
    .text-header-title {
        font-size: $line-height-24;
    }

    // HIDDEN
    .hidden-xs {
        display: none !important;
    }
}

@media only screen and (max-width: 401px) {
    .hidden-xxs {
        display: none !important;
    }

    .text-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}