.text-header-title {
    margin-top: 0;
    font-size: $text-size-title;
    color: $color-green-title;
    line-height: $line-height-title;
    margin-bottom: 64px;
}

.text-info-login {
    color: $color-green-title;
}

.text-description {
    color: $color-text-black;
}

.text-description-green {
    color: $color-green-title;
}