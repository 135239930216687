@import "../base/fonts.scss";
@import "../base/colors.scss";

.mat-flat-button {
  width: 100%;
  height: 48px;
  font-size: $line-height-16;
  color: $dl-color-neutral-darkest;
}
.btn {
  width: auto;
  height: 48px;
  background-color: $color-white;
  text-align: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}
.btn-d {
  width: auto;
  height: 48px;
  text-align: center;
  background: transparent;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}

.btn-back {
  @extend .btn;
  color: $color-text-black;
  border: 1px solid $color-text-black;
  font-size: 16px;
  font-family: "AlicorpWeb-Bold";
  padding: 12px 24px;

  &:hover {
    color: $color-white;
    background-color: $color-text-black;
    border: 1px solid $color-text-black;
    text-decoration: none;
  }
}

.btn-back-new {
  @extend .btn-d;
  color: $dl-color-neutral-dark;
  border: 1px solid $dl-color-neutral-dark;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 8rem;
  &:hover {
    color: $color-white;
    background-color: $dl-color-neutral-dark;
    border: 1px solid $dl-color-neutral-dark;
    text-decoration: none;
  }
}

.btn-add-checkout {
  min-width: 226px;
  width: auto;
  padding: 12px 24px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  background-color: $color-background-green-rgba;
  display: block;
  border-radius: 0.5rem;
  pointer-events: none;
  color: $color-white;
  border: 1px solid $color-background-green-rgba;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  display: inline-block;
  text-decoration: none;
  font-family: $dl-font-family-semibold;
  &.activeAddCar {
    background: $dl-color-brand-highlight-medium !important;
    pointer-events: auto !important;
    cursor: pointer;
  }
}

.btn-clear-gray {
  height: 32px;
  background-color: $color-text-d2d2;
  color: $color-text-black;
  line-height: 32px;
  font-size: 12px;
  font-family: $font-family-book;
  text-align: center;
  border-radius: .5rem;
  border: none;
  padding: 0 16px;
}

.btn-green,
.btn-red {
  width: auto;
  padding: 12px 12px;
  height: 48px;
  font-family: $dl-font-family-semibold;
  font-size: 16px;
  text-align: center;
  color: $color-white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: $color-green-1;
    border-color: $color-green-1;
  }
}

.btn-green {
  background-color: $color-green-button !important;
  border-color: $color-green-button !important;
}

.btn-red {
  background-color: $color-secondary !important;
  border-color: $color-secondary !important;
}
.btn-cancel {
	border: 1px solid $color-secondary;
	border-radius: 0.5rem;
	background-color: $dl-color-brand-primary-lightest;
	color: $color-secondary;
	font-size: 0.875rem;
	width: 30%;
	height: 2.5rem;
}
.btn-disabled,
.btn-disabled:hover {
  height: 2rem;
  background-color:  $dl-color-neutral-light;
  color:  $dl-color-neutral-dark;
  line-height: 2rem;
  font-size: 14px;
  font-family: $font-family-book;
  text-align: center;
  border-radius: .5rem;
  border: 1px solid $dl-color-neutral-medium;
  padding: 0 16px;
}