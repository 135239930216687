// container class

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width:1200px;
    }
  }

  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container,
    .container-sm {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      max-width:1200px;
    }
  }

@media screen and (max-width: 1200px) {
  .container {
    max-width:1200px;
  }
}

@media screen and (max-width: 990px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}