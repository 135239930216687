@import './colors.scss';

.p-carousel-content {
  overflow: hidden !important;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  border: 0 none;
  height: 40px;
  width: 30px;
  background: $color-primary;
  margin: auto 0;
  border-radius: 4px;
  color: $color-white;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: $color-white;
  border-color: none;
  background: $color-primary;
  opacity: none;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  cursor: pointer;
}

.p-carousel .p-carousel-indicators {
  padding: 0 1rem;
  margin: 0;
  height: .5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  color: transparent;

  &::marker {
    width: 0;
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: $color-white-1;
  width: 1rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 2px;
  border: none;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: $color-text-unit;
  cursor: pointer;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: $color-primary;
  border: none;
  color: $color-text-black;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight::marker {
  text-decoration: none;
  color: transparent;
}