.alert {
  position: relative;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
.alert-warning {
  @extend .alert;
  color: $color-text-black;
  background-color: $color-yellow-background;
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 14.4px;
  }
}
.alert-optional {
  @extend .alert;
  background-color: $color-background-alert;
  border-color: $color-background-alert;
}
.alert-optional-charging {
  @extend .alert;
  border-color: $color-background-alert;
  border: 1px solid $dl-color-neutral-medium;
  border-radius: 0.5rem;
  padding: 1rem 24px;
}
