.show-mobile,
.show-tablet {
    display: none;
}
.show-desktop,.show-desktop-tablet{
    display: block;
}
@media only screen and (max-width: 1023.9px) {
    .show-tablet {
        display: block;
    }
    .show-desktop{
        display: none;
    }
    .show-mobile {
    display: none;
}
}

@media only screen and (max-width: 767.9px) {
    .show-mobile {
        display: block;
    }
    .show-desktop-tablet{
        display: none;
    }
}