@import '../base/colors.scss';

.input-search ,.input-search-mobile{
    color: $dl-color-neutral-darkest;
    font-family: $dl-font-family-regular;
    font-size: $text-size-14;  
    text-decoration: none;
    width: 325px;
    height: 40px;
    border: none;
    border-radius: 5px;
}

.input-search:focus-visible, .input-search-mobile:focus-visible {
    border: none;
    outline: none;
}
.icon-search{
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}