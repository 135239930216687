//ALICORP COLORS//
$color-primary: #78b928;
$color-green-title: #1e5532;
$color-green-0: #3db167;
$color-green-1: #6aa423;
$color-green-2: #008a05;
$color-green-button: #3b8700;
$color-green-background: #effaef;
$color-secondary: #e62020;
$color-red-alert: #e2575d;
$color-red-hover: #dd1125;
$color-red-stock: #f04656;
$color-red-1: #ae0e1d;
$color-red-2: #d0021b;
$color-negative-medium: #df1978;
$color-negative-lightest: #faeff3;
$color-red-0: #fde4e4;
$color-white: #ffffff;
$color-white-0: #fcfcfc;
$color-white-1: #c6cbd4;
$color-gray: #dadbdc;
$color-gray-title: #545454;
$color-text-block: #d0d0d0;
$color-text-d2d2: #d2d2d2;
$color-background: #fafafa;
$color-background-0: #f7f7f7;
$color-background-alert: #eaecf1;
$color-unity: #766f6f;
$color-black: #000;
$color-gray-0: #70797e;
$color-text-black: #484d4f;
$color-text-clack-secundary: #6c757d;
$color-background-button-gray: #aaa;
$color-text-input: #495057;
$color-text-unit: #8a8a8a;
$color-text-excel: #e9ecef;
$color-text-null: #333333;
$color-product-border: #e0dcdc;
$color-text-products: #2f1515;
$color-text-excel-1: #ced4da;
$color-yellow-1: #f59b23;
$color-yellow: #e9c732;
$color-background-whatsapp: #e5ddd5;
$color-background-whatsapp-0: #f0f2f5;
$color-icon-whatsapp: #25d366;
$color-yellow-background: #ffeeb9;
$color-background-drag-active: #78b9280a;
$color-background-green-rgba: rgba(120, 185, 40, 0.5);
$color-background-black-rgba: rgb(159 159 159 / 42%);
$color-shadow-black-rgb: rgb(0 0 0 / 6%);
$color-shadow-gray-rgb: rgb(123 128 119 / 21%);
$color-shadow-gray-1-rgb: rgba(72, 77, 79, 0.2);
$color-shadow-gray-2-rgb: rgb(0 0 0 / 50%);
$color-shadow-gray-3-rgb: rgb(94 94 94 / 50%);
/* Primary */
$dl-color-brand-primary-darkest: #470000;
$dl-color-brand-primary-dark: #a30000;
$dl-color-brand-primary-medium: #e62020;
$dl-color-brand-primary-light: #e96767;
$dl-color-brand-primary-lightest: #faefef;
/* Highlight */
$dl-color-brand-highlight-darkest: #00331e;
$dl-color-brand-highlight-dark: #006644;
$dl-color-brand-highlight-medium: #3b8700;
$dl-color-brand-highlight-light: #91d65c;
$dl-color-brand-highlight-lightest: #f7faef;

/* Neutrals */
$dl-color-neutral-darkest: #202020;
$dl-color-neutral-dark: #6c6c6c;
$dl-color-neutral-medium: #dedede;
$dl-color-neutral-light: #f7f7f7;
$dl-color-neutral-lightest: #fcfcfc;
$dl-color-neutral-lead: #D9D9D9;
/* Support Positive */
$dl-color-support-positive-darkest: #003600;
$dl-color-support-positive-dark: #006b00;
$dl-color-support-positive-medium: #008a05;
$dl-color-support-positive-light: #79d279;
$dl-color-support-positive-stock:#79d27952;
$color-green-banner: #b8f38b;
$dl-color-yelow-row:#fff9b5;
$dl-color-pink-row:#eaedff;
$dl-color-support-positive-lightest: #effaef;

/* Support Warning */
$dl-color-support-warning-darkest: #472700;
$dl-color-support-warning-dark: #a34c00;
$dl-color-support-warning-medium: #e16c00;
$dl-color-support-warning-light: #f7b687;
$dl-color-support-warning-lightest: #faf4ef;

/* Support Warning */
$dl-color-support-negative-darkest: #5c001f;
$dl-color-support-negative-dark: #ad0048;
$dl-color-support-negative-medium: #df1978;
$dl-color-support-negative-light: #f787bc;
$dl-color-support-purple-light: #6172F3;
$dl-color-support-blue-light: #1B1B53;
$dl-color-support-purple: #9E77ED;
$dl-color-support-purple-medium: #F4EBFF;
$dl-color-support-negative-lightest: #faeff3;
$dl-color-client-background: #f0f9ff;
$dl-color-blue-background: #b9e6fe;
$dl-color-blue-dark: #0BA5EC;
$dl-color-blue-background-dark: #B9E6FE;
$dl-color-blue-medium: #B9E6FE;
$dl-color-blue-row:#E6F7FF;
.color-primary {
  color: $color-primary;
}
.color-secondary {
  color: $color-secondary
}
.color-green {
  color: $color-green-2;
}

.color-green-2 {
  color: $color-green-button;
}

.color-text-black {
  color: $color-text-black;
}

.color-white-1 {
  color: $color-white-1;
}

.color-text-product {
  color: $color-text-products;
}
.color-title-custom {
  color: $dl-color-neutral-darkest;
}
.color-subTitle-custom {
  color: $dl-color-neutral-dark;
}
.color-negative-alert {
  color:$dl-color-support-negative-medium;
}
.color-neutral-medium{
  color:$dl-color-neutral-medium;
}
.color-brand-highlight-medium {
color: $dl-color-brand-highlight-medium;
}
.color-blue-dark {
  color: $dl-color-blue-dark;
}
.color-neutral-lightest{
  color:$dl-color-neutral-lightest
}
.color-support-positive-dark{
  color: $dl-color-support-positive-dark;
}
.color-client-background{
  background: $dl-color-client-background;
}
.color-support-positive-medium{
  color: $dl-color-support-positive-medium;
}