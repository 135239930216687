$spaceamounts: (
  -110,
  -76,
  -60,
  -50,
  -40,
  -20,
  -19,
  -18,
  -17,
  -15,
  -12,
  -10,
  -7,
  -6,
  -5,
  -4,
  -3,
  -2,
  -1,
  -16,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  24,
  25,
  28,
  30,
  32,
  35,
  40,
  45,
  48,
  50,
  56,
  60,
  64,
  68,
  70,
  100,
  110,
  130,
  160
);
$spaceamounts1: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  50,
  51,
  52,
  53,
  56,
  57,
  58,
  70,
  75,
  80,
  85,
  90
);
$spaceweiht: (300, 400, 500, 600, 700);
// para tablas :)
@each $size in $spaceamounts1 {
  .size-#{$size} {
    width: #{$size}+ "%";
  }
}

//Height
@each $space in $spaceamounts {
  .h-#{$space} {
    height: #{$space}px;
  }
}

//Margin
@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px;
  }

  .mt-#{$space} {
    margin-top: #{$space}px;
  }

  .mb-#{$space} {
    margin-bottom: #{$space}px;
  }

  .mv-#{$space} {
    margin-top: #{$space}px;
    margin-bottom: #{$space}px;
  }

  .mh-#{$space} {
    margin-left: #{$space}px;
    margin-right: #{$space}px;
  }

  .ml-#{$space} {
    margin-left: #{$space}px;
  }

  .mr-#{$space} {
    margin-right: #{$space}px;
  }
}

//Padding
@each $space in $spaceamounts {
  .p-#{$space} {
    padding: #{$space}px;
  }

  .pt-#{$space} {
    padding-top: #{$space}px;
  }

  .pb-#{$space} {
    padding-bottom: #{$space}px;
  }

  .pv-#{$space} {
    padding-top: #{$space}px;
    padding-bottom: #{$space}px;
  }

  .ph-#{$space} {
    padding-left: #{$space}px;
    --padding-start: #{$space}px;
    --padding-end: #{$space}px;
    padding-right: #{$space}px;
  }

  .pl-#{$space} {
    padding-left: #{$space}px;
  }

  .pr-#{$space} {
    padding-right: #{$space}px;
  }

  .-ps-#{$space} {
    --padding-start: #{$space}px;
  }

  .-pt-#{$space} {
    --padding-top: #{$space}px;
  }

  .-pb-#{$space} {
    --padding-bottom: #{$space}px;
  }
}

//Font
@each $space1 in $spaceamounts1 {
  .fts-#{$space1} {
    font-size: #{($space1 / 16)}rem;
  }
}
@each $space in $spaceweiht {
  .fontw-#{$space} {
    font-weight: #{$space};
  }
}

@each $line in $spaceamounts1 {
  .line-height-#{$line} {
    line-height: #{$line}px;
  }
}

.d-flex {
  display: flex;
}

.hp-100 {
  height: auto;
  min-height: 100%;
}
.hp-12 {
  height: 12px;
}
.hp-16 {
  height: 16px;
}
.hp-48 {
  height: 48px;
}
.hp-80 {
  height: 5rem;
}
.vw-95 {
  width: 95%;
}
.vw-100 {
  width: 100%;
}
.vw-50 {
  width: 50%;
}
.vw-85 {
  width: 8.5rem;
}
.vw-106 {
  width: 10.6rem;
}
.vw-8 {
  width: 8rem;
}
.w-56 {
  width: 56px;
}
.w-60 {
  width: 60%;
}
.h-100 {
  height: 100vh;
}
.w-28{
  max-width: 28rem;
}
//Justify
.jc-center {
  justify-content: center;
}

.jc-fe {
  justify-content: flex-end;
}

.jc-fs {
  justify-content: flex-start;
}

.jc-sb {
  justify-content: space-between;
}

.jc-sa {
  justify-content: space-around;
}

//Text align
.ta-r {
  text-align: right;
}

.ta-s {
  text-align: start;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ml-a {
  margin-left: auto;
}

//AlignSelf
.as-center {
  align-self: center;
}

.as-start {
  align-self: flex-start;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}
.ai-start {
  align-items: start;
}

//Position
.pos-ab {
  position: absolute;
}

.pos-re {
  position: relative;
}

//scroll-verticall
.scroll-v {
  overflow-y: scroll;
}
//scroll
.scroll-hidden {
  overflow: hidden;
}
.w-space {
  white-space: nowrap;
}

//pointer
.cursor-pointer {
  cursor: pointer;
}
.box-s {
  box-sizing: border-box;
}
