@import "./colors.scss";
@import "./fonts.scss";
@import "./custom-theme.scss";
.container-box {
  max-width: 650px;
}

.icon-nav-white {
  width: 20px;
  height: 20px;
}

.icon-arrow-white-back {
  width: 14px;
  height: 14px;
}

.nav-container {
  width: 1200px;
  padding: 0 0;
}

.dividing-line {
  border-top: 1px dashed $color-gray;
  border-bottom: 0;
}
.dividing-line {
  border-top: 1px dashed $dl-color-neutral-medium; /* Utiliza border-top para la línea de división */
  border-bottom: 0;
}

.dividing-line-solid {
  border-top: 1px solid $color-gray;
  border-bottom: 0;
}
.dividing-line-black {
  border-top: 1px dashed $color-black;
  border-bottom: 0;
}

.show-box-shadow {
  box-shadow: 0 1px 7px 0 $color-shadow-black-rgb !important;
  -webkit-box-shadow: 0 1px 7px 0 $color-shadow-black-rgb !important;
  background-color: $color-white !important;
  border-radius: .5rem !important;
  -webkit-border-radius: .5rem !important;
}
.general-box-container {
  background: $dl-color-neutral-lightest;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  border: 1px solid $dl-color-neutral-medium;
}

//status
.status {
  color: $color-white;
  display: inline-block;
  height: 30px;
  width: 124px;
  border-radius: 3px;
  text-align: center;
  line-height: 32px;
}

.cancelado {
  @extend .status;
  background-color: $color-secondary;
}

.creado {
  @extend .status;
  background-color: $color-gray-0;
}

.confirmado {
  @extend .status;
  background-color: $color-green-0;
}

.proceso {
  @extend .status;
  background-color: $color-yellow-1;
}

.entregado {
  @extend .status;
  background-color: $color-primary;
}
//new status

.style-status {
  border-radius: 8px;
  width: max-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 1.25rem;
}

.cancelado-new {
  @extend .status;
  background: rgba($dl-color-support-negative-medium, 0.32);
  color: $dl-color-support-negative-dark; //falta definir
}

.creado-new {
  @extend .status;
  background: rgba($color-product-border, 0.32);
  color: $dl-color-neutral-dark;
}

.confirmado-new {
  @extend .status;
  background: $dl-color-brand-highlight-medium;
  color: $dl-color-neutral-lightest;
}

.proceso-new {
  @extend .status;
  background: rgba($dl-color-support-warning-medium, 0.32);
  color: $dl-color-support-warning-dark;
}

.entregado-new {
  @extend .status;
  background: rgba($dl-color-support-positive-light, 0.32);
  color: $dl-color-support-positive-dark;
}
//status binnacle
.status-binnacle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: .5rem;
  padding: 0 .5rem;
  height: 22px;
  width: fit-content;
}
.status-to-start {
  @extend .status;
  @extend .status-binnacle;
  background: rgba($dl-color-neutral-medium, 0.32);
  color: $dl-color-neutral-dark;
 
}

.status-delayed {
  @extend .status;
  @extend .status-binnacle;
  background: rgba($dl-color-support-warning-medium, 0.32);
  color: $dl-color-support-warning-dark;
}

.status-in-progress {
  @extend .status;
  @extend .status-binnacle;
  background: rgba($dl-color-support-purple-medium, 0.32);
  color: $dl-color-support-purple;
}

.status-completed {
  @extend .status;
  @extend .status-binnacle;
  background: rgba($dl-color-support-positive-light, 0.32);
  color: $dl-color-support-positive-dark;
}

.status-paused {
  @extend .status;
  @extend .status-binnacle;
  background: rgba($dl-color-blue-medium, 0.32);
  color: $dl-color-blue-dark;
}

.status-dismissed {
  @extend .status;
  @extend .status-binnacle;
  background: rgba($dl-color-brand-primary-light, 0.32);
  color: $color-secondary;
}

//
.header-table {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: $dl-color-neutral-darkest;
  color: $color-white;
  border-radius: 3px;
}

.car-progress-bar,
.account-progress-ba {
  border-radius: 0.25rem;
  height: 8px;
}

.text-wrap {
  flex-wrap: wrap;
}
.line-left {
  border-left: 1px dashed $color-black;
}

.body-export-pdf {
  height: 0px;
  overflow-x: scroll;
  position: absolute;
  z-index: -99;
}

.contentPDF {
  min-width: 1 122.5px;
  width: 1 122.5px;
}

.text-pdf-color {
  font-family: Arial, Helvetica, sans-serif;
  color: $color-black;
}

.size-pdf-1 {
  width: 210px;
  min-width: 200px;
}

.size-pdf-2 {
  width: 280px;
}

.size-pdf-detail-1 {
  width: 170px;
}
.size-pdf-detail-2 {
  width: 272px;
}

.dl-divider,
.dl-divider-red,
.dl-divider-black,
.dl-divider-neutral-medium {
  width: 100%;
  height: 1px;
}
.dl-divider-neutral-medium {
  background-color: $dl-color-neutral-medium;
}
.dl-divider {
  opacity: 0.32;
  background-color: $dl-color-neutral-dark;
}
.dl-divider-red {
  background-color: $dl-color-support-negative-light;
}
.dl-divider-black {
  background-color: $dl-color-neutral-dark;
}
.custom-tab-buttons button {
  cursor: pointer;
  padding: 0 1rem;
  background-color: $color-white;
  height: 2rem;
  border: 1px solid $dl-color-neutral-dark;
  border-radius: 1rem;
  margin-right: 0.5rem;
  font-size: 14px;
  color: $dl-color-neutral-darkest;
  font-family: $dl-font-family-bold;
}

.custom-tab-buttons button.active {
  background-color: $dl-color-brand-primary-medium; /* Cambia el color del botón seleccionado según tus preferencias */
  color: $color-white;
  border-radius: 1rem;
  height: 2rem;
  border: 1px solid $dl-color-brand-primary-medium;
  font-size: 14px;
  font-family: $dl-font-family-bold;
}
.datepicker-input {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0.5rem;
  border: 1px solid $color-shadow-gray-1-rgb;
  background-color: $color-white;
  color: $color-text-black;
  width: 100%;

  ::ng-deep.mat-form-field-wrapper {
    padding-bottom: 0 !important;
    height: 3.5rem;

    .mat-form-field-flex {
      height: 3.5rem;
      padding: 0 1rem;
      align-items: center;

      .mat-form-field-infix {
        border-top: 0.5em !important;
        padding: 0;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        height: 3.5rem;
        width: 100%;

        .mat-input-element {
          height: 3.5rem;
          max-width: 7rem;
          font-size: 1rem;
        }

        .mat-datepicker-toggle {
          height: 3.5rem;
        }
      }

      .mat-form-field-suffix {
        font-size: 1.5rem;

        .mat-icon-button {
          width: auto;
        }
      }
    }
  }
}
.datepicker-input-40 {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0.5rem;
  &:not(.error-date) {
    border: 1px solid $dl-color-neutral-dark !important;
  }
  background-color: $color-white;
  color:  $dl-color-neutral-dark;
  width: auto;

  ::ng-deep.mat-form-field-wrapper {
    padding-bottom: 0 !important;
    height: 2.5rem;

    .mat-form-field-flex {
      height: 2.5rem;
      padding: 0 1rem;
      align-items: center;

      .mat-form-field-infix {
        border-top: 0.5em !important;
        padding: 0;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        height: 2.5rem;
        .mat-datepicker-toggle {
          height: 2.5rem;
        }
        .mat-form-field-label-wrapper{
          top: 0;
          padding-top: 0;
          .mat-form-field-label{
            top:0.8rem
                    }
        }
        .mat-date-range-input{
            color: $dl-color-neutral-dark;
            font-family: $dl-font-family-regular;
            .mat-date-range-input-container{
              .mat-date-range-input-separator{
                color: $dl-color-neutral-dark;
                font-family: $dl-font-family-regular;
              }
            }
          }
       
      }

      .mat-form-field-suffix {
        font-size: 1.5rem;

      }
    }
  }
  ::ng-deep  &.error-date {
    .mat-form-field-wrapper {
      border: 1px solid $dl-color-support-negative-medium !important;
      border-radius: 0.5rem !important;
      color: $dl-color-support-negative-medium !important;
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-date-range-input{
            color: $dl-color-support-negative-medium !important;
            .mat-date-range-input-container{
              .mat-date-range-input-separator{
                color: $dl-color-support-negative-medium !important;
              }
            }
          }
        }
      }
      .mat-form-field-label{
        color: $dl-color-support-negative-medium !important; 
      }
    }

  }
}


::ng-deep .mat-form-field.mat-focused .mat-form-field-label{
  display: none;
}
::ng-deep .mat-form-field-underline {
  display: none;
}

::ng-deep .mat-datepicker-toggle .mat-focus-indicator:focus {
  background-color: $color-white !important;
}

::ng-deep.mat-button-focus-overlay {
  background-color: $color-white !important;
  background: $color-white !important;
}
::ng-deep.mat-calendar-body-cell-content {
  display: block !important;
  font-size: 1rem;
  font-family: $dl-font-family-regular;
}

::ng-deep .mat-calendar-body-selected {
  background-color: transparent;
  /* Elimina el fondo circular */
  color: $dl-color-brand-primary-medium;
  /* Mantiene el color del texto */
  background: transparent;
}

::ng-deep .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: #e620204f;
}

::ng-deep.mat-calendar-body-disabled
  > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: transparent;
}

::ng-deep .mat-calendar-body-today {
  background-color: transparent !important;
  /* Elimina el fondo predeterminado */
}

::ng-deep .mat-calendar-body-selected::after {
  content: "";
  display: block;
  width: 4px;
  /* Ancho del punto */
  height: 4px;
  /* Alto del punto */
  background-color: currentColor;
  /* Color del punto */
  color: $dl-color-brand-primary-medium;
  border-radius: 50%;
  /* Hace que el punto sea redondo */
  margin: 0 auto;
  /* Centra el punto horizontalmente */
  margin-top: 2px;
  /* Espacio entre el número y el punto */
}
::ng-deep .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical)
{
  border-color:transparent !important;
}
.color-active {
  color: $dl-color-brand-primary-medium;
}
.color-disabled {
  color: $dl-color-neutral-dark;
}
::ng-deep.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none !important;
}
::ng-deep.hide-select-arrow .mat-select-arrow {
  display: none;
}
.hidden-overflow-x{
  overflow-x: hidden;
}
.auto-overflow-x{
  overflow-x: auto;
}