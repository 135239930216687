$font-family-bold: "AlicorpWeb-Bold";
$font-family-bold-italic: "AlicorpWeb-Bold-Italic";
$font-family-book: "AlicorpWeb-Book";
$font-family-book-italic: "AlicorpWeb-Book-Italic";
$font-family-light: "AlicorpWeb-Light";
$font-family-light-italic: "AlicorpWeb-Light-Italic";
$font-family-medium: "AlicorpWeb-Medium";
$font-family-medium-italic: "AlicorpWeb-Medium-Italic";
/* Font Family*/
$dl-font-family-light: 'AlicorpSans-Light'; /* 300 */
$dl-font-family-regular: 'AlicorpSans-Regular'; /* 400 */
$dl-font-family-bold: 'AlicorpSans-Bold'; /* 700 */
$dl-font-family-semibold: 'AlicorpSans-Semibold'; /* 600 */
$dl-font-family-italic: 'AlicorpSans-BoldItalic';/* 700 */

.font-bold {
  font-family: $font-family-bold;
}

.font-bold-italic {
  font-family: $font-family-bold-italic;
}

.font-book {
  font-family: $font-family-book;
}
.font-regular {
  font-family: $dl-font-family-regular;
}
.font-book-italic {
  font-family: $font-family-book-italic;
}

.font-light {
  font-family: $font-family-light;
}

.font-light-italic {
  font-family: $font-family-light-italic;
}

.font-medium {
  font-family: $font-family-medium;
}

.font-medium-italic {
  font-family: $font-family-medium-italic;
}
.font-semiBold {
  font-family: $dl-font-family-semibold;
}
.font-bold-dali {
  font-family: $dl-font-family-bold;
}
.font-family-italic {
  font-family: $dl-font-family-italic;
}
// font size
$text-size-input: 0.9rem;
$text-size-title: 32px;
$text-size-24: 24px;
$text-size-20: 20px;
$text-size-18: 18px;
$text-size-16: 16px;
$text-size-14: 14px;
$text-size-12: 12px;
$text-size-11: 11px;
$text-size-10: 10px;

// font weight
$font-weight-500: 500;
$font-weigh-n: normal;
$font-weigh-m: medium;

// font height
$line-height-title: 40px;
$line-height-28: 28px;
$line-height-24: 24px;
$line-height-23: 23px;
$line-height-20: 20px;
$line-height-16: 16px;
$line-height-15: 15px;
$line-height-12: 12px;
$line-height-11: 11px;
